

















import IncidentService from '../services/incident';
import EndPoint from '../services/endpoint';
import Vue from 'vue';
import KpiCard from '@/components/KpiCard.vue'; // @ is an alias to /src
import Filtered from '@/components/Filter.vue';
import Travaux from '@/views/Travaux.vue';
import { ObjectiveKind } from '@/types/gitlab';
import { DateTime } from 'luxon';

export default Vue.extend({
  name: 'Overview',
  components: {
    KpiCard,
    Travaux,
    Filtered,
  },
  data() {
    return {
      customerId: null,
      date: '',
      date1: Date,
      date2: Date,
      item: false,
      timer: 0,
    };
  },
  async mounted() {
    if (localStorage.customerId) {
      this.customerId = localStorage.customerId;
    } else {
      await this.$router.push('/Login');
    }
    if (this.$route.query?.q) {
      localStorage.selectedKpi = this.$route.query.q;
    }
    if (localStorage.date1) {
      this.date1 = localStorage.date1;
    }
    if (localStorage.date2) {
      this.date2 = localStorage.date2;
    }
    await this.getItem();
    this.interval();

    this.$root.$on('currentClient', data => {
      if (this.$route.path != '/overview') return;
      this.refreshData(data);
    });
  },
  watch: {
    $route(to, from) {
      if (to.query?.q) {
        localStorage.selectedKpi = to.query.q;
        this.refreshData({ selectedKpi: to.query.q });
      }
    },
  },
  destroyed(): void {
    clearInterval(this.timer);
  },
  methods: {
    async refreshData(a) {
      if (a.customerId) this.customerId = a.customerId;
      this.date = DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss');
      this.reload();
    },
    async reload() {
      await this.getItem();
    },
    interval() {
      if (this.timer > 0) {
        clearInterval(this.timer);
        this.timer = 0;
      }
      this.timer = window.setInterval(() => this.reload(), 60000); //refresh 1 minute
    },
    async getItem() {
      await IncidentService.getBy(
        ObjectiveKind.UNRESOLVED_INCIDENT,
        EndPoint.get(this.customerId) + EndPoint.params(ObjectiveKind.UNRESOLVED_INCIDENT),
      ).then(response => {
        this.item = response;
      });
    },
  },
});
